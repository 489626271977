import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { useColorMode } from "theme-ui"

import Layout from "../../layouts/default"

import Breadcrumb from "../../components/breadcrumb"
import Bullet from "../../components/bullet"
import BulletList from "../../components/bullet-list"
import Box from "../../components/box"
import Column from "../../components/column"
import Columns from "../../components/columns"
import RotateLetter from "../../components/rotate-letter"
import Paragraph from "../../components/paragraph"
import Constrain from "../../components/constrain"
import Heading from "../../components/heading"
import NoBreak from "../../components/no-break"
import PageTitle from "../../components/page-title"
import Seo from "../../components/seo"
import Stack from "../../components/stack"
import Text from "../../components/text"
import TextLink from "../../components/textlink"
import VideoPlayer from "../../components/video-player"

import logoLiteraturSommer from "../../images/partner-logos/literatursommer.svg"
import logoLiteraturSommerWhite from "../../images/partner-logos/literatursommer-white.svg"

const Step = ({ step, title, children }) => {
  return (
    <Box bg="muted" p={[6, 6, 8]}>
      <Columns collapse={[true, false]}>
        <Column width="content">
          <RotateLetter size={12} fontSize={6}>
            {step}
          </RotateLetter>
        </Column>
        <Column>
          <Stack space={6}>
            <Stack space={3}>
              <Heading as="h3" level={4}>
                {title}
              </Heading>
              {children}
            </Stack>
          </Stack>
        </Column>
      </Columns>
    </Box>
  )
}

const WorkshopHipHop = () => {
  const [colorMode] = useColorMode()

  const data = useStaticQuery(graphql`
    query WorkshopHipHopWQuery {
      poster: file(relativePath: { eq: "workshops/poster-spax.jpg" }) {
        ...largeImage
      }
    }
  `)

  return (
    <Layout backdrop="workshop-hiphop">
      <Seo
        title="Anfangen, zu rappen – ein Tutorial von Spax"
        description="In diesem Video erklärt euch der Rapper Spax, wie ihr euren Gedanken und dem, was euch umtreibt, in Rap-Texten Ausdruck verleiht und wie ihr das Ganze in Sprechgesang verwandelt."
      />
      <Stack>
        <Breadcrumb
          items={[
            {
              title: "Digital",
              link: "/digital",
            },
            {
              title: "Workshops",
              link: "/workshops",
            },
            {
              title: "Anfangen, zu rappen – ein Tutorial von Spax",
              link: "/workshops/philosophie-hiphop",
            },
          ]}
        />
        <PageTitle>
          Anfangen, zu rappen – ein <NoBreak>Tutorial</NoBreak> von Spax
        </PageTitle>
        <Heading as="h2" level={5}>
          Workshop für Jugendliche und junge Erwachsene ab 15 Jahren
        </Heading>
        <Paragraph dropcap={true}>
          In diesem Video erklärt euch der Rapper Spax, wie ihr euren Gedanken
          und dem, was euch umtreibt, in Rap-Texten Ausdruck verleiht und wie
          ihr das Ganze in Sprechgesang verwandelt. Schaut hinein und erfahrt
          mehr über den Zusammenhang von Feuermeldern und Steuergeldern, über
          die magische Zahl 4 in Rap-Texten, über Hooks und Bars und wie man
          sich dabei von Hölderlin inspirieren lassen kann.
        </Paragraph>
        <VideoPlayer src="/workshops/spax-hiphop" poster={data.poster} />

        <Constrain>
          <Stack>
            <Heading as="h2" level={3}>
              Die 9 Schritte in der Übersicht
            </Heading>
            <Stack space={3}>
              <Step step={1} title="Kreativität">
                <BulletList size={[3, 3, 4, 5]}>
                  <Bullet>Hab' keine Angst, Fehler zu machen</Bullet>
                  <Bullet>Lass deiner Kreativität freien Lauf</Bullet>
                </BulletList>
              </Step>
              <Step step={2} title="Motivation">
                <BulletList size={[3, 3, 4, 5]}>
                  <Bullet>Welches Ziel willst du erreichen?</Bullet>
                  <Bullet>Wen willst du erreichen?</Bullet>
                  <Bullet>Was ist der gemeinsame Nenner?</Bullet>
                </BulletList>
              </Step>
              <Step step={3} title="Brainstorm">
                <BulletList size={[3, 3, 4, 5]}>
                  <Bullet>Sammle, was dir durch den Kopf geht</Bullet>
                  <Bullet>Setze keinen Filter an</Bullet>
                </BulletList>
              </Step>
              <Step step={4} title="Mindmap">
                <Paragraph>
                  Stelle Bezüge her und strukturiere deine Ideen
                </Paragraph>
              </Step>
              <Step step={5} title="Reime">
                <BulletList size={[3, 3, 4, 5]}>
                  <Bullet>
                    Sammle Reime – auf sie kommt es an beim Rappen
                  </Bullet>
                  <Bullet>
                    Du kannst es auch mit mehrsilbigen Doppelreimen (wie zum
                    Beispiel Steuergelder und Feuermelder) versuchen
                  </Bullet>
                </BulletList>
              </Step>
              <Step step={6} title="Anfangen">
                <Paragraph>
                  Fange an, zu schreiben, und hangle dich von Zeile zu Zeile
                </Paragraph>
              </Step>
              <Step step={7} title="Struktur/Aufbau">
                <BulletList size={[3, 3, 4, 5]}>
                  <Bullet>
                    Ein Rap-Song hat in der Regel 16 Zeilen (oder auch Bars)
                  </Bullet>
                  <Bullet>Strukturiere deinen Text in 4er Ketten</Bullet>
                </BulletList>
              </Step>
              <Step step={8} title="Chorus/Hook/Refrain">
                <BulletList size={[3, 3, 4, 5]}>
                  <Bullet>
                    Baue ›Haken‹ (Hooks) und Wiederholungen ein. Das motiviert
                    und animiert dein Publikum
                  </Bullet>
                  <Bullet>Der Hook sollte einfach und eingängig sein</Bullet>
                </BulletList>
              </Step>
              <Step step={9} title="Von Hölderlin lernen?">
                <Paragraph>
                  Lass dich von anderen Künstler*innen und Schreiber*innen
                  inspirieren
                </Paragraph>
              </Step>
            </Stack>
          </Stack>
        </Constrain>

        <Constrain>
          <Stack space={6} alignX="center">
            <Text>
              Im Rahmen des{" "}
              <TextLink href="https://www.literatursommer.de/">
                Literatursommers
              </TextLink>{" "}
              ›Hölderlin und Hegel – 250 Jahre Sprache und Vision‹ der
              Baden-Württemberg Stiftung
            </Text>
            <img
              src={
                colorMode === "light"
                  ? logoLiteraturSommer
                  : logoLiteraturSommerWhite
              }
              alt="Logo Literaturland Baden-Württemberg"
              width="82"
              height="92"
              sx={{ width: "auto", height: "92px" }}
            />
          </Stack>
        </Constrain>
      </Stack>
    </Layout>
  )
}

export default WorkshopHipHop
